/** @format */

import ChoosePlanFarming from '../choosePlanFarming';
import Countdown from 'react-countdown';
import style from './index.module.scss';
import iconUSDT from '../../../assets/roll/modals/USDT.png';
import { useEffect, useState } from 'react';
import { ModalRewardUSDTType } from '../../../lib/types/modal';
import ButtonClaim from '../../buttons/buttonClaim';

const ModalRewardUSDT = ({
	onClickButtonQuickUSDT,
	onClosePopup,
	timeCountDown,
	onClickWithDraw,
}: ModalRewardUSDTType) => {
	const [isComplete, setComplete] = useState(false);
	return (
		<ChoosePlanFarming 
			onClose={onClosePopup}
			iconPopup={iconUSDT}
			customStyle={{
				width: '230px',
				height: 'auto',
				top: '-30%',
			}}
		>
		<div className={style.popup__usdt_wrapper}>
		<h2 className={style.popup__usdt_title}>You won $5 USDT!</h2>
		<p className={style.popup__usdt_description}>
			Security check takes 48 hours. <br />
			If approved, you can withdraw the <br /> $5 balance.
		</p>
		<div className={style.popup__usdt_list_button}>
			<div>
				<ButtonClaim
					backGround={'bgc_button_up_level'}
					onClickButton={onClickButtonQuickUSDT}
				>
					<p className={style.text_button_quickly}>I want to verify quickly</p>
				</ButtonClaim>
				<div
					style={{
						marginTop: '10px',
					}}
				>
					<ButtonClaim
						backGround={'button_purple'}
						onClickButton={isComplete ? onClickWithDraw : undefined}
					>
						{!isComplete ? (
							<Countdown
								date={Number(timeCountDown)}
								onComplete={() => {
									setComplete(true);
								}}
							/>
						) : (
							<p className={style.text_button_quickly}>Withdraw</p>
						)}
					</ButtonClaim>
				</div>
			</div>
		</div>
	</div>
		</ChoosePlanFarming>
	);
};

export default ModalRewardUSDT;
