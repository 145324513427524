import { isMobile } from "react-device-detect";
import React, { useEffect, useRef, useState } from "react";
import { RWebShare } from "react-web-share";
import {
	useAccount,
	usePublicClient,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import styles from "./index.module.scss";
import ProfileTeleMiniApp from "./components/ProfileTeleMiniApp";
import UnityComponent from "./components/UnityComponent";
import QuestPage from "./components/QuestComponent";
import { parseEther } from "viem";
import {
	apiPillageJourneyInfo,
	apiRegisterAccountWithAddress,
} from "../../../services/claimApiService";
import { apiGetMyAccount } from "../../../services/accountApiService";
import { encodeJwt } from "../../../utils/extension";
import { stavaxAccount } from "../../../utils/ConnectAvax";
import {
	CHECKIN_CONTRACT_ADDRESS_BASE,
	abi,
} from "../../../ABI/baseAbiTestNet";
import { tabData } from "../../../utils/constants/pages";
import { useLocation } from "react-router-dom";

const TeleAppPage: React.FC = () => {
	const [screen, setScreen] = useState(1);
	const [indexTab, setIndexTab] = useState(1);
	const [isUIVisible, setIsUIVisible] = useState(false);
	const [dataRenderHeader, setDataRenderHeader] = useState();
	const [isJoinPillage, setIsJoinPillage] = useState(false);
	const [isClient, setIsClient] = useState(false);
	const [isLoadingFirst, setIsLoadingFirst] = useState(false);
	const [deviceIsMobile, setDeviceIsMobile] = useState(false);
	const [previousShareEvent, setPreviousShareEvent] = useState(null);
	const [userIdTele, setUserIdTele] = useState<string | string[] | undefined>(
		undefined
	);
	const [deviceKey, setDeviceKey] = useState(null);
	const shareRef = useRef<HTMLParagraphElement>(null);
	const { writeContractAsync } = useWriteContract();
	const { switchChainAsync } = useSwitchChain();
	const publicClient = usePublicClient();
	const account = useAccount();
	const [isCheckedIn, setCheckIn] = useState<string | null>(null);
	const [isClaim, setClaim] = useState(false);
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const userId: any = params.get("user_id");
		const referId = params.get("refer_id");
		const userName = params.get("user_name");
		
		setUserIdTele(userId);
		localStorage.setItem("_userId", userId);
		handleLoginWhenWelcome(userId, referId, userName);
	}, []);

	const handleShare = async () => {
		const shareLink = `https://t.me/Slime_Revolution_bot?start=${userIdTele}`;
		if (navigator.share) {
			try {
				await navigator.share({
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: shareLink,
				});
			} catch (error) {}
		}
	};

	const copyTextToClipboard = (text: string) => {
		var textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			var successful = document.execCommand("copy");
			var msg = successful ? "successful" : "unsuccessful";
		} catch (err) {
			console.error("Oops, unable to copy", err);
		}
		document.body.removeChild(textArea);
	};

	useEffect(() => {
		window.addEventListener("message", function (event) {
			if (event.data.type === "INVITE") {
				if (shareRef.current) {
					shareRef.current.click();
				}
			}
			if (event.data.type === "CHECKIN") {
				dailyCheckIn();
			}
			if (event.data.type === "COPY_TO_CLIPBOARD") {
				copyTextToClipboard(
					`https://t.me/Slime_Revolution_bot?start=${userIdTele}`
				);
			}
		});
	}, []);

	const handleCallApiPillageJourneyInfoAgain = async () => {
		const responsePillageJourneyInfo: any = await apiPillageJourneyInfo();
		if (
			responsePillageJourneyInfo?.success &&
			responsePillageJourneyInfo?.data?.is_join_pillage === true
		) {
			localStorage.setItem(
				"journey_info",
				JSON.stringify(responsePillageJourneyInfo?.data)
			);
			setIsLoadingFirst(false);
			setScreen(3);
			setIndexTab(1);
			setIsJoinPillage(true);
		} else {
			setIsLoadingFirst(false);
		}
	};

	const handleCallApiInFoUser = async () => {
		const responseInfoFarming = await apiGetMyAccount();
		if (responseInfoFarming) {
			localStorage.setItem(
				"info_data_user",
				JSON.stringify(responseInfoFarming?.data)
			);
		}
	};

	const handleLoginWhenWelcome = async (
		userId: number | string,
		referId: any,
		userName: any
	) => {
		localStorage.removeItem("dataInfoFarming");
		localStorage.removeItem("info_data");
		localStorage.removeItem("journey_info");
		localStorage.removeItem("info_data_user");
		const value = {
			TelegramId: userId,
			FirstReferId: referId,
			UserName: userName,
		};
		const payLoadData = await encodeJwt(value);
		const responseRegisterAccount: any =
			await apiRegisterAccountWithAddress({
				value: payLoadData,
			});
		if (responseRegisterAccount?.success) {
			localStorage.setItem(
				"_gameAccessToken",
				responseRegisterAccount?.data?.access_token
			);
			localStorage.setItem("_AddressAccount", payLoadData);
			localStorage.setItem(
				"info_data",
				JSON.stringify(responseRegisterAccount?.data)
			);
			handleCallApiPillageJourneyInfoAgain();
			handleCallApiInFoUser();
			setIsUIVisible(true);
			setIndexTab(1);
		}
	};

	useEffect(() => {
		if (isMobile) {
			setDeviceIsMobile(true);
		} else {
			setDeviceIsMobile(false);
		}
	}, []);

	function getQueryParams() {
		const params = new URLSearchParams(window.location.search);
		const queryParams: { [key: string]: string } = {};
		params.forEach((value, key) => {
			queryParams[key] = value;
		});
		return queryParams;
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			setCheckIn(null);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isCheckedIn]);

	useEffect(() => {
		const queryParams = getQueryParams();
	}, []);

	const dailyCheckIn = async () => {
		if (account?.address == undefined) {
			const session: any = await stavaxAccount.connect();
			const botURL = stavaxAccount.getTgBotWebAppURL(session).value;
		} else {
			try {
				const isWrongChain = account?.chainId !== 8453;
				if (isWrongChain) {
					await switchChainAsync({ chainId: 8453 });
				}
				stavaxAccount.openTgBot();
				const hash = await writeContractAsync({
					abi,
					address: CHECKIN_CONTRACT_ADDRESS_BASE,
					functionName: "mint",
					value: parseEther("0.00001"),
				});
				await publicClient?.waitForTransactionReceipt({
					hash: hash,
				});
				const iframe: any = document.getElementById(
					"unityIframe"
				) as HTMLIFrameElement | null;
				if (iframe) {
					iframe.contentWindow.postMessage(
						{ type: "CHECKEDIN", text: "" },
						"*"
					);
				}

				setCheckIn("Check in successfully");
			} catch (e: unknown) {
				if (e instanceof Error && "details" in e) {
					const error = e as any;
					if (error.details === "User cancel") {
						setCheckIn("You have declined the transaction");
					} else {
						setCheckIn("You have already checked-In today");
					}
				} else {
					console.error("An unexpected error occurred:", e);
				}
			}
		}
	};

	return (
		<div className={styles.main1}>
			<div className={`${styles.main} `}>
				<RWebShare
					data={{
						title: "Slime Revolution",
						text: "Play Slime Revolution with me!",
						url: `https://t.me/Slime_Revolution_bot?start=${userIdTele}`,
					}}
					onClick={() => console.log("shared successfully!")}
				>
					<p ref={shareRef}></p>
				</RWebShare>
				{isUIVisible && !isLoadingFirst ? (
					<div
						className={`${
							indexTab === 0 && styles.background__start
						}  ${indexTab === 0 && styles["bgc_start_farming"]}`}
					>
						{tabData.map((item, index) => {
							if (indexTab === item?.id) {
								return (
									<div key={index}>
										{item?.renderComponent}
									</div>
								);
							}
						})}
						 <div style={{ display: indexTab === 1 ? 'block' : 'none' }}>
                            <UnityComponent visible={indexTab === 1} setIsClient={setIsClient} />
                        </div>
						{indexTab === 2 && <ProfileTeleMiniApp />}
						{indexTab === 0 && <QuestPage />}
						{isCheckedIn != null && (
							<div
								className={styles.checkedInStyle}
							>{`${isCheckedIn}`}</div>
						)}
					</div>
				) : (
					<div></div>
				)}

				<div>
					<>
						<div className={styles.shadowBottom}></div>
						<div className={styles.bottomNavigation}>
							{tabData.map((e, index) => (
								<div
									key={index}
									onClick={() => {
										setIndexTab(index);
									}}
									id="button"
								>
									<img
										src={
											index == indexTab
												? e.imgActive
												: e.imgInActive
										}
										alt={`${e.imgActive}`}
										height={50}
									/>
								</div>
							))}
						</div>
					</>
				</div>
			</div>
		</div>
	);
};

export default TeleAppPage;
