// AptosWalletConnect.tsx
import React from "react";
import styles from "./index.module.scss";
import { useWallet, WalletName } from "@aptos-labs/wallet-adapter-react";

type AptosWalletConnectType = {
	onClose: () => void;
};
export default function AptosWalletConnect({
	onClose,
}: AptosWalletConnectType) {
	const { wallets, connect } = useWallet();

	const onConnect = async (walletName: WalletName) => {
		try {
			connect(walletName);
			onClose();
		} catch (error) {
			console.error("Error connecting wallet:", error);
		}
	};

	return (
		<div className={styles.walletContainer}>
			{wallets!.map((wallet) => (
				<button
					key={wallet.name}
					className={styles.walletButton}
					onClick={() => onConnect(wallet.name)}
				>
					Connect {wallet.name}
				</button>
			))}
		</div>
	);
}
