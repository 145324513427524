import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import App from './App';
import TeleAppPage from './components/page/TeleAppPage';

const Main = () => {
    return (
        <Router>
          <Routes>
            <Route path="/" element={<TeleAppPage />} />
          </Routes>
        </Router>
      );
};

export default Main;
