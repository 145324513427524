import iconShieldActive from '../../assets/roll/shield_Active.png';
import iconShieldInActive from '../../assets/roll/shield_inActive.png';

import Home from '../../assets/images/teleImage/Home.png';
import Attack from '../../assets/images/teleImage/Attack.png';
import Quest from '../../assets/images/teleImage/Quest.png';
import Ranking from '../../assets/images/teleImage/Ranking.png';
import Shop from '../../assets/images/teleImage/Shop.png';
import ShopActive from '../../assets/images/teleImage/ShopActive.png';
import RankingActive from '../../assets/images/teleImage/RankingActive.png';
import QuestActive from '../../assets/images/teleImage/QuestActive.png';
import HomeInActive from '../../assets/images/teleImage/HomeActive.png';
import AttackActive from '../../assets/images/teleImage/AttackActive.png';
import QrCode from '../../assets/images/teleImage/QrCode.png';

const listDataShield = [iconShieldActive, iconShieldActive, iconShieldInActive];

const tabData = [
	// {
	//     id: 0,
	//     imgActive: Home,
	//     imgInActive: HomeInActive,
	//     renderComponent: (
	//         <StartFlowComponent
	//             dataRenderHeader={dataRenderHeader}
	//             setDataRenderHeader={setDataRenderHeader}
	//             setIsClient={setIsClient}
	//             screen={3}
	//             setScreen={setScreen}
	//             isFirstOpen={() => {
	//                 setIndexTab(2);
	//                 setIsJoinPillage(true);
	//             }}
	//         />
	//     ),
	// },
	{
		id: 1,
		imgActive: QuestActive,
		imgInActive: Quest,
		renderComponent: '',
	},
	{
		id: 2,
		imgActive: AttackActive,
		imgInActive: Attack,
		renderComponent: '',
	},
	// {
	//     id: 3,
	//     imgActive: ShopActive,
	//     imgInActive: Shop,
	// },
	{
		id: 4,
		imgActive: RankingActive,
		imgInActive: Ranking,
		renderComponent: '',
	},
];

export { listDataShield, tabData };
