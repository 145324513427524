const ENDPOINT = {
	INFO_FARM: "api/farming-mini-game/info",
	START_FARM: "api/farming-mini-game/start",
	START_JOURNEY: "/api/pillage/start/1/100",
	REGISTER: "/api/user/telegram",
	PILLAGE_JOURNEY_INFO: "/api/pillage/journey/info",
	RECEIVE_CLAIM: 'api/farming-mini-game/claim',
	UP_LEVEL_SHIP: 'api/farming-mini-game/ship/upgrade',
	// USER_INFO: '/api/user/info'
	USER_INFO: '/api/user/info-tele'
};

export { ENDPOINT };
