import React from "react";
import ReactDOM from "react-dom/client";
import './styles/globalStyle.scss';
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import reportWebVitals from "./reportWebVitals";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import { createConfig, http, WagmiProvider } from "wagmi";
import { avalanche, avalancheFuji, base, baseSepolia } from "wagmi/chains";
import { injected, metaMask, walletConnect } from "wagmi/connectors";
import TeleAppPage from "./components/page/TeleAppPage";
import { configMainnet } from "./config";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./main";


const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
const wallets = [new PetraWallet()];

const router = createBrowserRouter([
    {
      path: "/",
      element: <TeleAppPage />,
    },
  ]);
root.render(
    
	<React.StrictMode>
		<Provider store={store}>
			<AptosWalletAdapterProvider plugins={wallets} autoConnect={true}>
				<WagmiProvider config={configMainnet}>
					<QueryClientProvider client={queryClient}>
						<Main />
					</QueryClientProvider>
				</WagmiProvider>
			</AptosWalletAdapterProvider>
		</Provider>
	</React.StrictMode>
);

reportWebVitals();
