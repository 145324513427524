/** @format */

import React from "react";
import styles from "./circleButtonStyle.module.scss";

type CircleButtonType = {
    onTap: () => void;
    title: string;
    isGrayColor?: boolean;
    customStyle?: React.CSSProperties;
}
export default function CircleButton({
    onTap,
    title,
    isGrayColor = false,
    customStyle,
}: CircleButtonType) {
    return (
        <button
            id="button"
            onClick={onTap}
            style={{
                background: "transparent",
                ...customStyle,
            }}
            className="active-btn">
            {
                <div
                    className={
                        isGrayColor === false ? styles.body : styles.body1
                    }>
                    <span className={styles.childrenStyle}>{title}</span>
                </div>
            }
        </button>
    );
}
