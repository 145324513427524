import React from "react";
import style from "./style.module.scss";
import { ButtonClaimType } from "../../../lib/types/button";


const ButtonClaim = ({ backGround, children, onClickButton = () => {} }: ButtonClaimType) => {
	return (
		<div id="button"  onClick={onClickButton}>
			{children}
		</div>
	);
};

export default ButtonClaim;
