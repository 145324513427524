import style from './style.module.scss';
import React from 'react';
import buttonCancel from '../../../assets/roll/button_cancel.png'
import LoadingCommon from '../../CommonUI/LoadingApp';

type ChoosePlanFarmingType = {
	children?: React.ReactNode;
	iconPopup?: any;
	onClose?: () => void;
	isLoading?: boolean;
	customStyle? : React.CSSProperties;
};
export default function ChoosePlanFarming({
	children,
	iconPopup,
	onClose,
	isLoading = false,
	customStyle,
}: ChoosePlanFarmingType) {
	return (
		<div className={style.main}>
			<div className={`${style.popup}`}>
				<LoadingCommon visible={isLoading} width={80} height={80} isLeft10={true} />
				<img
					src={iconPopup}
					width={171}
					alt="slime"
					className={style.popup__icon}
					style={customStyle}
				/>
				<img
					src={buttonCancel}
					width={36}
					height={38}
					alt="button cancel"
					className={style.button__close}
					onClick={onClose}
				/>
				{children}
			</div>
			<div className={`${style.modalOverlay}`}></div>
		</div>
	);
}
