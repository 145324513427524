/** @format */

import { Config, createConfig, http } from "wagmi";
import { avalanche, avalancheFuji, base, baseSepolia } from "wagmi/chains";
import { StavaxAccount } from "@stavaxio/account-sdk";
import { injected, metaMask, walletConnect } from "wagmi/connectors";
import { configMainnet } from "../config";

export const stavaxAccount = new StavaxAccount({
	projectID: "1da6a2ab-3ccb-4aa2-afd9-b4047469065b",
	// apiURL: "https://account-api-dev.stavax.io",
	// tgBotWebAppURL: "https://t.me/stv_dev_bot/app",
	wagmiConfig:  configMainnet,
});
