import React, { useEffect, useState } from "react";

type UnityComponentProps = {
	visible: boolean;
	setIsClient: React.Dispatch<React.SetStateAction<boolean>>;
	// other props...
};

const UnityComponent: React.FC<UnityComponentProps> = ({
	visible,
	setIsClient,
}) => {
	const [gameAccessToken, setGameAccessToken] = useState<string | null>();
	useEffect(() => {
		setIsClient(true);
	}, []);

	useEffect(() => {
		if (localStorage.getItem("_gameAccessToken") !== null) {
			setGameAccessToken(localStorage.getItem("_gameAccessToken"));
		}
	}, [gameAccessToken]);

	return (
		<div style={{ maxHeight: "calc(100vh - 76px)", marginBottom: "76px" }}>
			{gameAccessToken != null && (
				<iframe
					id="unityIframe"
					style={{
						width: "100%",
						height: "calc(100vh - 76px)",
					}}
					src={`https://unitygame10.slimerevolution.com?access_token=${localStorage.getItem(
						"_gameAccessToken"
					)}&user_id=${localStorage.getItem("_userId")}?time=1`}
					scrolling="no"
					frameBorder="0"
				></iframe>
			)}
		</div>
	);
};

export default UnityComponent;
