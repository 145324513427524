/** @format */
import * as jose from 'jose';
import { parseEther } from 'viem';
import { JWT_SIGNING } from '../constants';
import { stavaxAccount } from '../ConnectAvax';
export const encodeJwt = async (data: any) => {
	const enc = new TextEncoder();
	const secret = enc.encode(JWT_SIGNING);
	return await new jose.SignJWT(data).setProtectedHeader({ alg: 'HS256' }).sign(secret);
};

export const decodeJwt = async (token: string) => {
	try {
		const enc = new TextEncoder();
		const secret = enc.encode(JWT_SIGNING);
		const { payload } = await jose.jwtVerify(token, secret);
		return payload;
	} catch (e) {
		return;
	}
};
export const decodeJwtWallet = async (token: string) => {
	try {
		const enc = new TextEncoder();
		const secret = enc.encode(JWT_SIGNING);
		const { payload } = await jose.jwtVerify<jose.JWTPayload>(token, secret);
		return payload;
	} catch (e) {
		return;
	}
};

export const decodeBase64 = (base64String: string) => {
	try {
		if (!base64String) {
			return;
		}
		const decodedString = Buffer.from(
			base64String?.replace('data:application/json;base64,', ''),
			'base64'
		).toString('utf-8');

		const decodedJSON = JSON.parse(decodedString);
		return decodedJSON;
	} catch (error) {
		return null;
	}
};
export const requestTransfer = async (
	eth: string,
	sendTransactionAsync: any
) => {
	try {
		await stavaxAccount.openTgBot();
		const transactionHash = await sendTransactionAsync({
			to: '0x30863B08E027E35E0F3BA95C198080683c00E19f',
			value: parseEther(eth),
		});
	} catch (e) {
		console.log({ e });
	}
};
