/** @format */

import instance from '../baseApi';
import axios from 'axios';
import { ENDPOINTS } from './endpoint';
import { decodeJwt, encodeJwt } from '../../utils/extension';

export const checkInDaily = () => {
	return instance.post(ENDPOINTS.API_CHECK_IN_CONTRACT);
};

export const telegramUser = () => {
	return instance.get(ENDPOINTS.API_QUEST_TELEGRAM);
};

export const telegramConfirm = (questId: number) => {
	let url = `${ENDPOINTS.API_QUEST_TELEGRAM_CONFIRM}${questId}`;
	return instance.post(url);
};

export const checkInToday = (data: {}) => {
	return instance.post(ENDPOINTS.API_INFO_CHECK_IN_SMART_WALLET, data);
};

export const getMyAccount = () => {
	return instance.get(ENDPOINTS.API_USER_INFO);
};

export const updateMyAccount = (data: {}) => {
	return instance.post(ENDPOINTS.API_UPDATE_SMART_WALLET, data);
};

export const startWithDraw = async (data?: {}) => {
	try {
		const responseGetInfo = await instance.post(ENDPOINTS.API_WITHDRAW_START_CLICK);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const withdrawTimeInfo = async () => {
	try {
		const responseGetInfo = await instance.get(ENDPOINTS.API_WITHDRAW_INFO);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const withDrawUsd = () => {
	return instance.post(ENDPOINTS.API_WITHDRAW_USDT);
};

export const changeStatusWithDraw = (data: {}) => {
	return instance.post(ENDPOINTS.API_WITHDRAW_UPDATE_STATUS, data);
};

export const getShopPackageData = () => {
	return instance.get(ENDPOINTS.API_SHOP_PACKAGE);
};

export const getCollectionList = async () => {
	try {
		const responseGetInfo = await instance.get(ENDPOINTS.API_COLLECTIONS_LIST);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const getListCardReadyToMint = async () => {
	try {
		const responseGetInfo = await instance.get(ENDPOINTS.API_CARDS_WAIT_LISTS_MINT);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const collectionsById = async (id: number) => {
	try {
		const responseGetInfo = await instance.get(`${ENDPOINTS.API_COLLECTIONS_LIST_CARDS}${id}`);
		if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
	} catch (e) {
		return e;
	}
};

export const check7day = () => {
	return instance.get(ENDPOINTS.API_USER_REWARD_7DAY);
};

export const reward7Day = () => {
	return instance.post(ENDPOINTS.API_USER_REWARD_7DAY_POST);
};

export const packageInfo = (userId: number) => {
	return instance.get(`${ENDPOINTS.API_SHOP_PACKAGE_USER}${userId}`);
};

export const getUserId = (id: number) => {
	return instance.get(`${ENDPOINTS.API_USER_BY_ID}${id}`);
};


export const apiGetMyAccount = async (payloadRequest?: {}) => {
    try {
        const responseGetInfo = await instance.get(
            ENDPOINTS.USER_INFO,
            payloadRequest
        );
        if (responseGetInfo) return await decodeJwt(responseGetInfo?.data);
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const requestUpdateProfile = async (walletAddress?:{}) => {
    try {
        const value = {
            address_smart_wallet: walletAddress,
        };
        const payLoadData = await encodeJwt(value);
        const res = await updateMyAccount({ value: payLoadData });
    } catch (e) {
        console.log({ e });
    }
};