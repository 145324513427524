export interface QuestDataRes {
    quest_id: number;
    quest_name: string;
    quest_reward_amount: number;
    quest_status: number;
    quest_sort: number | null;
}

interface Data {
    list_user_quest: QuestDataRes[];
}

interface ResponseQuest {
    message: string;
    status: number;
    data: Data;
    options: object;
    success: boolean;
    iat: number;
}
export const getQuestFromJSON = (json:any) : ResponseQuest => {
    return JSON.parse(json);
}

