/** @format */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	useAccount,
	useBalance,
	useConnect,
	usePublicClient,
	useReadContract,
	useSwitchChain,
	useWriteContract,
} from "wagmi";
import SelectChain from "./SelectChain";
import { RWebShare } from "react-web-share";
import styles from "./index.module.scss";

import slimeShop from "../../../../../assets/images/teleImage/TitleQuest.png";
import DailyCheckin7 from "../../../../../assets/images/teleImage/DailyCheckin7.png";
import Reward10 from "../../../../../assets/images/teleImage/Reward10.png";
import Reward20 from "../../../../../assets/images/teleImage/Reward20.png";
import Reward30 from "../../../../../assets/images/teleImage/Reward30.png";
import Reward30s from "../../../../../assets/images/teleImage/Reward30s.png";
import Reward30Inside from "../../../../../assets/images/teleImage/reward30inside.png";
import ComingSoon from "../../../../../assets/images/tokens/Comingsoon.png";
import questsImg from "../../../../../assets/images/teleImage/quests.png";
import enableBtn from "../../../../../assets/images/teleImage/SRIconArrow.png";
import disableBtn from "../../../../../assets/images/teleImage/disableBtn.png";
import dividerImg from "../../../../../assets/images/teleImage/dividerImg.png";
import doneBtn from "../../../../../assets/images/tokens/Check_icon.png";
import energyPlus from "../../../../../assets/images/teleImage/energyPlus.png";
import Slime from "../../../../../assets/roll/Slime.svg";
import checkinedImg from "../../../../../assets/images/teleImage/checkinImg.png";
import Reward from "../../../../../assets/images/teleImage/Reward.png";
import dailycheckin from "../../../../../assets/images/teleImage/dailycheckin.png";
import DailyCheckinBanner from "../../../../../assets/images/teleImage/DailyCheckinBanner.png";
import {
	getQuestFromJSON,
	QuestDataRes,
} from "../../../../../Modal/Quest/userQuest";
import {
	getUserInforFromJSON,
	UserInforData,
} from "../../../../../Modal/User/userInfor";
import {
	CHECKIN_CONTRACT_ADDRESS_BASE,
	abi,
} from "../../../../../ABI/baseAbiTestNet";
import { decodeJwt, encodeJwt } from "../../../../../utils/extension";
import {
	apiGetMyAccount,
	telegramConfirm,
	telegramUser,
	updateMyAccount,
} from "../../../../../services/accountApiService";
import { stavaxAccount } from "../../../../../utils/ConnectAvax";
import LoadingCommon from "../../../../../commonsUi/CommonUI/LoadingApp";
import ChoosePlanFarming from "../../../../../commonsUi/modals/choosePlanFarming";
import CircleButton from "../../../../../commonsUi/commonButton/gameButton/CircleButton/CircleButton";
import AptosWalletConnect from "../../walletConnect";
import {
	InputTransactionData,
	useWallet,
} from "@aptos-labs/wallet-adapter-react";
import { aptos, MODULE_ADDRESS } from "../../../../../aptosConfig";

interface QuestData {
	title: string;
	id?: number;
	status?: boolean;
	key?: string;
	img: any;
	url?: string;
}

const data: QuestData[] = [
	{
		title: "Follow Our Channel",
		id: 1,
		status: false,
		key: "FollowOurChannel",
		img: Reward10,
		url: "https://t.me/SlimeRoyaleChannel",
	},
	{
		title: "Join Our Group",
		status: false,
		id: 2,
		key: "JoinOurGroup",
		img: Reward10,
		url: "https://t.me/SlimeRoyale",
	},
	{
		title: "Follow Our Twitter",
		status: false,
		id: 3,
		key: "FollowTwitter",
		img: Reward10,
		url: "https://x.com/SlimeRoyale/",
	},
	{
		title: "Connect Stavax Smart Wallet",
		status: false,
		id: 6,
		img: Reward10,
	},
	{
		title: "Join Playnation",
		status: false,
		img: Reward10,
		id: 8,
		url: "https://t.me/Playnation_bot/app?startapp=4DRlZJzWG",
	},
	{
		title: "Sync TON Wallet",
		status: true,
		img: Reward10,
		id: 9,
	},
	{
		title: "Connect your Email",
		status: true,
		img: Reward10,
		id: 4,
	},
];

const QuestPage: React.FC = () => {
	const publicClient = usePublicClient();
	const { connectAsync } = useConnect();
	const { writeContractAsync } = useWriteContract();
	const accountWalletInfo = useSelector((state: any) => state.accountWallet);
	const dispatch = useDispatch();
	const { switchChainAsync } = useSwitchChain();
	const [isClaim, setClaim] = useState(false);
	const [questData, setQuestData] = useState<QuestDataRes[]>([]);
	const [inviteFriendItem, setInviteFriendItem] =
		useState<QuestDataRes | null>(null);
	// const account = useAccount();
	const [isShowLoading, setShowLoading] = useState(false);
	const [isShowLoading1, setShowLoading1] = useState(false);
	const [questSelected, setQuestSelected] = useState<QuestData | null>(null);
	const [isShowInviteFriend, setShowInviteFriend] = useState(false);
	const [indexQuestSelected, setIndexQuestSelected] = useState(-1);
	const [showCheckIn, setShowCheckIn] = useState(false);
	const [isCheckedIn, setCheckIn] = useState<string | null>(null);
	const [inforData, setInfoData] = useState<UserInforData | null>(null);
	const shareRef = useRef<HTMLParagraphElement>(null);
	const [isShowConnectWalelt, setShowConnectWallet] = useState(false);
	const {
		wallets,
		connect,
		account,
		disconnect,
		signAndSubmitTransaction,
		signTransaction,
	} = useWallet();

	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			hasCheckedInToday();
		}
	};

	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener(
				"visibilitychange",
				handleVisibilityChange
			);
		};
	}, []);
	const requestUpdateProfile = async (walletAddress: string) => {
		try {
			const value = {
				address_smart_wallet: walletAddress,
			};
			const payLoadData = await encodeJwt(value);
			await updateMyAccount({ value: payLoadData });
		} catch (e) {
			console.log({ e });
		}
	};
	useEffect(() => {
		if (account?.address !== undefined) {
			requestUpdateProfile(account.address);
		}
	}, [account]);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);

	const checkinOnChain = async () => {
		try {
			const response = await signAndSubmitTransaction({
				sender: account?.address,
				data: {
					function: `${MODULE_ADDRESS}::CheckinTest::mint`,
					functionArguments: [],
				},
			});
			try {
				await aptos.waitForTransaction({
					transactionHash: response.hash,
				});
			} catch (error) {
				hasCheckedInToday();
			}
		} catch (e) {
			hasCheckedInToday();
		}
	};

	const hasCheckedInToday = async () => {
		if (!account) return [];
		try {
			const isCheckedIn = await aptos.view({
				payload: {
					function: `${MODULE_ADDRESS}::CheckinTest::has_minted_today`,
					typeArguments: [],
					functionArguments: [account.address],
				},
			});
			if (isCheckedIn[0]) {
				setClaim(true);
				setCheckIn("Check in successfully");
			} else {
				setClaim(true);
				setCheckIn("Check in failure");
			}
		} catch (e: any) {
			console.log({ e });
		}
	};
	const dailyCheckIn = async () => {
		if (account?.address === undefined) {
			// const session = await stavaxAccount.connect();
			// const botURL = stavaxAccount.getTgBotWebAppURL(session!).value;
			setShowConnectWallet(true);
		} else {
			try {
				checkinOnChain();
				// const isWrongChain = account?.chainId !== 8453;
				// if (isWrongChain) {
				// 	await switchChainAsync({ chainId: 8453 });
				// }
				// stavaxAccount.openTgBot();
				// const hash = await writeContractAsync({
				// 	abi,
				// 	address: CHECKIN_CONTRACT_ADDRESS_BASE,
				// 	functionName: 'mint',
				// 	value: BigInt(0),
				// });
				// await publicClient?.waitForTransactionReceipt({
				// 	hash: hash,
				// });
				// setClaim(true);
				// setCheckIn('Check in successfully');
			} catch (e: any) {
				// setClaim(false);
				// if (e.details == 'User cancel') {
				// 	setCheckIn('You have declined the transaction');
				// } else {
				// 	setCheckIn(
				// 		e.message +
				// 			'/n' +
				// 			e.details +
				// 			`connect Status: ${account.isConnected} ---- ${account.status}`
				// 	);
				// }
			}
		}
	};
	const handleButtonClick = (url: string) => {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			window.location.href = url;
		} else {
			window.open(url, "_blank", "noopener,noreferrer");
		}
	};

	const telegramUserRequest = async () => {
		try {
			const resTelegramUser = await telegramUser();
			const payload = await decodeJwt(resTelegramUser.data);
			const value = getQuestFromJSON(JSON.stringify(payload));
			setQuestData(value.data.list_user_quest);

			const inviteFriend = value.data.list_user_quest.find(
				(item) => item.quest_id === 7
			);
			if (inviteFriend) {
				setInviteFriendItem(inviteFriend);
			}
		} catch (error) {
			console.error("Error fetching Telegram user:", error);
		}
	};

	const telegramConfirmRequest = async (id: number) => {
		try {
			const confirm = await telegramConfirm(id);
			await decodeJwt(confirm.data);
			telegramUserRequest();
		} catch (e) {}
	};
	const handleCallApiPillageJourneyInfoAgain = async () => {
		const responsePillageJourneyInfo = await apiGetMyAccount();
		const value = getUserInforFromJSON(
			JSON.stringify(responsePillageJourneyInfo)
		);
		if (value?.success) {
			setInfoData(value);
		} else {
		}
	};

	useEffect(() => {
		telegramUserRequest();
		handleCallApiPillageJourneyInfoAgain();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setCheckIn(null);
		}, 10000);
		return () => clearTimeout(timer);
	}, [isCheckedIn]);

	return (
		<div className={styles.main}>
			<img
				src={slimeShop}
				alt="slimeShop"
				style={{
					width: "250px",
					height: "54px",
					display: "flex",
					alignSelf: "center",
					justifySelf: "center",
				}}
			/>
			{/* <SelectChain /> */}
			<div className={styles.titleQuestStyle}>Daily Quest</div>
			<div
				className={styles.checkInStyle}
				onClick={() => {
					if (!isClaim) {
						dailyCheckIn();
					}
				}}
			>
				<img src={Reward} alt="Reward" width={66} />
				<p className={styles.checkinTextStyle}>Check in On Chain</p>
				<img
					src={!isClaim ? enableBtn : doneBtn}
					alt="enableBtn"
					width={30}
				/>
			</div>
			<div className={styles.titleQuestStyle}>{`Invite Friends ${
				inforData?.data?.invitedCount || 0
			}/5`}</div>
			<div
				className={styles.checkInStyle}
				onClick={() => {
					// questData?.find((item) => item.quest_id === 7)?.quest_status === 0
					// 	? setShowInviteFriend(true)
					// 	: null;
				}}
			>
				<img src={Reward30s} alt="Reward" width={66} />
				<div>
					<p className={styles.checkinTextStyle}>Invite Friend </p>
					<p className={styles.checkInDesStyle}>(+30 Energy each)</p>
				</div>
				<img
					src={
						questData?.find((item) => item.quest_id === 7)
							?.quest_status === 1
							? doneBtn
							: enableBtn
					}
					alt="enableBtn"
					width={30}
				/>
			</div>

			{/* <img
            src={DailyCheckin7}
            alt="DailyCheckin7"
            style={{
                width: "130px",
                height: "30px",
                marginLeft: "20px",
                marginTop: "20px",
            }}
        /> */}
			{/* <DailySevenChecking /> */}
			<div className={styles.titleQuestStyle}>Quest</div>
			<div className={styles.body}>
				{data.map((e, index) => (
					<div
						key={index}
						onClick={() => {
							if (
								!e.status &&
								questData[index]?.quest_status === 0
							) {
								setIndexQuestSelected(index);
								setQuestSelected(e);
							}
						}}
					>
						<>
							<div className={styles.itemStyles}>
								<img src={e.img} alt={e.img} height={60} />
								<p
									className={
										e.status
											? styles.itemTextStyleGray
											: styles.itemTextStyle
									}
								>
									{e.title}
								</p>
								<div
									style={{
										width: "60px",
									}}
								>
									{questData?.length > 0 &&
									!data[index].status ? (
										<img
											src={
												questData?.length > 0
													? questData[index]
															?.quest_status === 1
														? doneBtn
														: !e.status
														? enableBtn
														: disableBtn
													: enableBtn
											}
											alt="statusBtn"
											width={
												questData?.length > 0
													? questData[index]
															?.quest_status === 1
														? 35
														: !e.status
														? 30
														: 30
													: 30
											}
											className={"active-btn"}
										/>
									) : (
										<img
											src={ComingSoon}
											alt="ComingSoon"
											width={40}
										></img>
									)}
								</div>
							</div>
							<img
								src={dividerImg}
								alt="dividerImg"
								height={5}
								style={{
									width: "80vw",
									height: "5",
									marginLeft: "16px",
								}}
							/>
						</>
					</div>
				))}
			</div>
			<LoadingCommon
				visible={isShowLoading}
				width={80}
				height={80}
				isLeft10={true}
			/>
			{showCheckIn && (
				<div
					className={styles.notice1}
				>{`You haven't completed this quest.`}</div>
			)}
			{isCheckedIn != null && (
				<div className={styles.checkedInStyle}>{`${isCheckedIn}`}</div>
			)}
			{questSelected != null && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setQuestSelected(null)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							{questSelected?.title}
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								width: "80vw",
								justifyContent: "center",
							}}
						>
							<img
								src={energyPlus}
								alt="energyPlus"
								height={40}
							/>
						</div>
						<CircleButton
							title={
								questSelected.id == 6 ? "Connect now" : "Follow"
							}
							onTap={async () => {
								if (questSelected.id == 6) {
									localStorage.setItem(
										questSelected.key!,
										questSelected.key!
									);
									const session =
										await stavaxAccount.connect();
									const botURL =
										stavaxAccount.getTgBotWebAppURL(
											session!
										).value;
								} else {
									localStorage.setItem(
										questSelected.key!,
										questSelected.key!
									);
									handleButtonClick(questSelected.url!);
								}
							}}
						/>
						<CircleButton
							isGrayColor={true}
							title="Check quest status"
							onTap={() => {
								if (
									localStorage.getItem(
										questSelected.key ?? ""
									) != null
								) {
									setQuestSelected(null);
									telegramConfirmRequest(
										questSelected.id ?? 0
									);
								} else {
									setShowCheckIn(true);
								}
							}}
						/>
					</div>
				</ChoosePlanFarming>
			)}
			<RWebShare
				data={{
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					// url: `https://t.me/Slime_Revolution_bot?start=${localStorage.getItem(
					// 	'_userId'
					// )}`,
				}}
				onClick={() => console.log("shared successfully!")}
			>
				<p ref={shareRef}></p>
			</RWebShare>
			{isShowInviteFriend && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setShowInviteFriend(false)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Invite Friend to earn more Energy
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								width: "80vw",
								justifyContent: "center",
							}}
						>
							<img
								src={Reward30Inside}
								alt="energyPlus"
								height={40}
							/>
						</div>
						<CircleButton
							title="Invite now"
							onTap={async () => {
								setShowInviteFriend(false);
								if (shareRef.current) {
									shareRef.current.click();
								}
							}}
						/>

						<CircleButton
							isGrayColor={true}
							title={`Check quest status ${
								inforData?.data?.invitedCount || 0
							}/5`}
							onTap={() => {
								if (inforData?.data?.invitedCount! >= 5) {
									telegramConfirmRequest(7);
									setShowInviteFriend(false);
								} else {
									setShowCheckIn(true);
								}
							}}
						/>
					</div>
				</ChoosePlanFarming>
			)}
			{isShowConnectWalelt && (
				<AptosWalletConnect
					onClose={() => setShowConnectWallet(false)}
				/>
			)}
		</div>
	);
};

export default QuestPage;
