import React, { useEffect, useRef, useState } from "react";
import {
	Config,
	useAccount,
	useConnect,
	useDisconnect,
	useSendTransaction,
} from "wagmi";
import { RWebShare } from "react-web-share";
import styles from "./index.module.scss";

import iconCopy from "../../../../../assets/images/iconCopy.png";
import PirateIcon from "../../../../../assets/roll/modals/Pirate.png";
import accountImg from "../../../../../assets/images/teleImage/accountImg.png";
import iconUSDT from "../../../../../assets/roll/modals/USDT.png";
import emblem from "../../../../../assets/images/teleImage/emblem.png";
import emblem1 from "../../../../../assets/images/teleImage/emblem1.png";
import { InfoData, ResUrl } from "../../../../../lib/models/api";
import {
	renderBtnType,
	RenderTextFieldType,
} from "../../../../../lib/types/button";
import {
	apiGetMyAccount,
	requestUpdateProfile,
	startWithDraw,
	withdrawTimeInfo,
} from "../../../../../services/accountApiService";
import { stavaxAccount } from "../../../../../utils/ConnectAvax";
import { requestTransfer } from "../../../../../utils/extension";
import ChoosePlanFarming from "../../../../../commonsUi/modals/choosePlanFarming";
import ButtonClaim from "../../../../../commonsUi/buttons/buttonClaim";
import ModalWithDrawUSDT from "../../../../../commonsUi/modals/modalWithDrawUSDT";
import ModalRewardUSDT from "../../../../../commonsUi/modals/modalRewardUSDT";
import { writeClipboardText } from "../../../../../utils/function/text";
import AptosWalletConnect from "../../walletConnect";
import WalletConnect from "../../walletConnect";
import { useWallet } from "@aptos-labs/wallet-adapter-react";

type ProfileTeleGramMiniAppAppProps = {};

const ProfileTeleGramMiniApp: React.FC<
	ProfileTeleGramMiniAppAppProps
> = ({}) => {
	const [showCopy, setShowCopy] = useState<boolean>(false);
	const [showCopy1, setShowCopy1] = useState<boolean>(false);
	const [resUrl, setResUrl] = useState<ResUrl>();
	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const [infoData, setInfoData] = useState<InfoData | null>(null);
	// const { disconnect, disconnectAsync } = useDisconnect();
	const { sendTransactionAsync } = useSendTransaction();
	const [isShowInvite, setShowInvite] = useState<boolean>(false);
	const [showCheckIn, setShowCheckIn] = useState<boolean>(false);
	const [showPopupUSDT, setShowPopupUSDT] = useState<boolean>(false);
	const [showPopupAsk, setShowPopupAsk] = useState<boolean>(false);
	const [isShowWithDraw, setShowWithDraw] = useState<boolean>(false);
	const [timeClickWithDraw, setTimeClickWithDraw] = useState<number>(0);
	const shareRef = useRef<HTMLDivElement | null>(null);
	// const { connect, connectors } = useConnect<Config, unknown>();
	const [isShowConnectWalelt, setShowConnectWallet] = useState(false);
	const { account, disconnect } = useWallet();

	const RenderBtn = ({ isEnable, title, onClick }: renderBtnType) => {
		return (
			<div
				className={`${
					isEnable ? styles.enableBtnStyle : styles.disableBtnStyle
				} active-btn`}
				onClick={onClick}
			>
				{title}
			</div>
		);
	};
	const requestStartWithDraw = async () => {
		const resStartWithDraw = await startWithDraw();
	};
	const requestWithdrawTimeInfo = async () => {
		return await withdrawTimeInfo();
	};

	const handleCallApiPillageJourneyInfoAgain = async (): Promise<void> => {
		const responsePillageJourneyInfo: any = await apiGetMyAccount();
		if (responsePillageJourneyInfo?.success) {
			setInfoData(responsePillageJourneyInfo.data);
		} else {
		}
	};

	const handleShowPopupUSDT = async () => {
		await requestStartWithDraw();
		const resWithdrawTimeInfo: any = await requestWithdrawTimeInfo();
		if (resWithdrawTimeInfo) {
			if (new Date(resWithdrawTimeInfo.data.TimeWithDraw) <= new Date()) {
				setShowWithDraw(true);
				return;
			}

			setTimeClickWithDraw(
				new Date(resWithdrawTimeInfo.data.TimeWithDraw).getTime()
			);
		}
		setShowPopupUSDT(true);
	};

	const onClickButtonQuickUSDT = () => {
		setShowPopupUSDT(false);
		setShowInvite(true);
	};
	const handleClosePopupUSDT = () => {
		setShowPopupUSDT(false);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCopy(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, [showCopy]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCopy1(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, [showCopy1]);

	useEffect(() => {
		if (!hasMounted) {
			handleCallApiPillageJourneyInfoAgain();
			setHasMounted(true);
		}
	}, [hasMounted]);

	useEffect(() => {
		const handleUpdateAccount = async (): Promise<any> => {
			if (account?.address != undefined) {
				await requestUpdateProfile(account.address);
			}
		};
		handleUpdateAccount();
	}, [account]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);

	const RenderTextField = ({
		placeHolder,
		title,
		isButton = true,
		btnTile,
		isEnable,
		onClick,
		onClickText,
	}: RenderTextFieldType) => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "self-start",
				}}
			>
				<p className={styles.titleStyle}>{title}</p>
				<div className={styles.inputStyle1}>
					<p
						onClick={onClickText}
						style={{
							width: "58vw",
							overflow: "hidden",
							textAlign: "start",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{placeHolder}
					</p>
					{isButton ? (
						<RenderBtn
							onClick={onClick}
							title={btnTile}
							isEnable={isEnable}
						></RenderBtn>
					) : (
						<>
							<img
								onClick={() => {
									setShowCopy(true);
									writeClipboardText(resUrl?.userId);
								}}
								src={iconCopy}
								alt="iconCopy"
								style={{
									marginRight: "10px",
									width: "30px",
									height: "30px",
									marginTop: "10px",
								}}
							/>
							{showCopy && (
								<div className={styles.copyStyle}>Copied</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	};

	const RenderBody = () => {
		return (
			<>
				<div className={styles.cardTileStyle}> Your Account</div>
				<div className={styles.bodyHeader}>
					<RenderTextField
						title="Game Account"
						placeHolder={resUrl?.userId}
						isButton={false}
					/>
					{/* <RenderTextField
						title="Email"
						placeHolder="Coming soon"
						btnTile="Connect"
						isEnable={false}
					/> */}

					<RenderTextField
						title="Aptos Wallet"
						placeHolder={`${
							account?.address !== undefined
								? account?.address
								: ""
						}`}
						btnTile={`${
							account?.address !== undefined
								? "Disconnect"
								: "Connect"
						}`}
						isEnable={true}
						onClickText={() => {
							writeClipboardText(account?.address);
							setShowCheckIn(true);
						}}
						onClick={async () => {
							console.log({ account });
							if (account?.address !== undefined) {
								disconnect();
								return;
							} else {
								console.log("Asdf");
								setShowConnectWallet(true);
							}

							// const session = await stavaxAccount.connect();
							// console.log({ session });
							// const botURL = stavaxAccount.getTgBotWebAppURL(
							// 	session!
							// ).value;
							// console.log({ botURL });
						}}
					/>
					{/* <RenderTextField
						title="TON Wallet"
						placeHolder="Coming soon"
						btnTile="Connect"
						onClick={async () => {
							console.log({ account });
							requestTransfer("0.001", sendTransactionAsync);
						}}
					/> */}
					<img
						src={emblem1}
						alt="emblem1"
						height={180}
						style={{
							position: "absolute",
							top: "10%",
						}}
					/>
				</div>
			</>
		);
	};
	useEffect(() => {
		setResUrl({
			userId: localStorage.getItem("_userId"),
		});
	}, []);
	const handleShare = async () => {
		const shareLink = `https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`;
		if (navigator.share) {
			try {
				await navigator.share({
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: shareLink,
				});
			} catch (error) {}
		} else {
		}
	};
	const RenderHeader = () => {
		return (
			<>
				<div
					style={{
						width: "200px",
						display: "flex",
						marginLeft: "106px",
						alignItems: "center",
						justifyContent: "center",
						marginBottom: "30px",
					}}
				>
					<img
						src={accountImg}
						alt="accountImg"
						style={{ width: "66vw", height: "44px" }}
					/>
					{/* <Image
                        src={buttonCancel}
                        width={30}
                        height={30}
                        alt="button cancel"
                        onClick={onClick}
                    /> */}
				</div>
				<div className={styles.cardTileStyle}> Your Assets</div>
				<div className={styles.bodyHeader}>
					{/* <div className={styles.inputStyle}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Image
                                src={spcHeader}
                                alt="spcHeader"
                                height={40}
                            />
                            <p className={styles.valueHeader}>0</p>
                        </div>
                        <RenderBtn title="Withdraw" />
                    </div> */}
					<div className={styles.inputStyle}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<img src={iconUSDT} alt="iconUSDT" height={40} />
							<p className={styles.valueHeader}>
								{`${
									infoData?.Usdt != null
										? Number(infoData?.Usdt).toFixed(2)
										: 0
								}`}{" "}
								$
							</p>
						</div>
						<RenderBtn
							title="Withdraw"
							isEnable={
								infoData?.Usdt != null &&
								infoData?.Usdt >= 5 &&
								true
							}
							onClick={
								infoData?.Usdt != null && infoData?.Usdt >= 5
									? handleShowPopupUSDT
									: () => {}
							}
						/>
					</div>
					<p className={styles.textStyle}>
						Reach minimum 5$ to withdraw
					</p>
					<img
						src={emblem}
						alt="emblem"
						className={styles.imgHeaderStyle}
						height={56}
					/>
				</div>
			</>
		);
	};
	const RenderBottom = () => {
		return (
			<>
				<div className={styles.cardTileStyle}> Your Referral Code</div>
				<div className={styles.bodyHeader}>
					<RWebShare
						data={{
							title: "Slime Revolution",
							text: "Play Slime Revolution with me!",
							url: `https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`,
						}}
						onClick={() => console.log("shared successfully!")}
					>
						<RenderTextField
							placeHolder={`https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`}
							btnTile="Share"
							isEnable={true}
							isButton={true}
						/>
					</RWebShare>
					{/* <Image
                        src={emblem1}
                        alt="emblem"
                        className={styles.imgHeaderStyle}
                        height={56}
                    /> */}
				</div>
			</>
		);
	};

	return (
		<div className={styles.main}>
			<RenderHeader />
			<RenderBody />
			<RenderBottom />
			{showCheckIn && (
				<div className={styles.notice1}>{`address copied`}</div>
			)}
			{isShowInvite && (
				<ChoosePlanFarming
					iconPopup={PirateIcon}
					onClose={() => setShowInvite(false)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Ask 5 friends for support to verify quickly
						</p>
						<div>
							<div className={styles.copyMainStyle}>
								<img
									onClick={() => {
										setShowCopy1(true);
										writeClipboardText(
											`https://t.me/Slime_Revolution_bot?start=${resUrl?.userId} \n\nPlay-to-Airdrop! Start your journey to earn tSPC. The more tSPC you get, the bigger your Airdrop. Earn now!`
										);
									}}
									src={iconCopy}
									alt="iconCopy"
									style={{
										width: "30px",
										height: "30px",
										marginTop: "10px",
										position: "absolute",
										right: "4px",
									}}
								/>
								<div className={styles.classRefer}>
									<p className={styles.referLinkStyle}>
										Your referral link
									</p>
									<p
										className={styles.desReferLink}
									>{`https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`}</p>
								</div>
								{showCopy1 && (
									<div className={styles.copyStyle1}>
										Copied
									</div>
								)}
							</div>
						</div>
						<div className={styles.button_popup_add_now}>
							<div>
								<ButtonClaim
									backGround={"bgc_button_up_level"}
									onClickButton={() => {
										setShowInvite(false);
										if (shareRef.current) {
											shareRef.current.click();
										}
									}}
								>
									<p className={styles.button_ask_now}>
										Ask now
									</p>
								</ButtonClaim>
								<p
									className={styles.text_invite_count}
								>{`*Need at least 5 friends to verify (${
									infoData?.invitedCount || 0
								}/5)`}</p>
							</div>
						</div>
					</div>
				</ChoosePlanFarming>
			)}
			<RWebShare
				data={{
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: `https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`,
				}}
				onClick={() => console.log("shared successfully!")}
			>
				<p ref={shareRef}></p>
			</RWebShare>
			{showPopupUSDT && (
				<ModalRewardUSDT
					onClickButtonQuickUSDT={onClickButtonQuickUSDT}
					onClosePopup={handleClosePopupUSDT}
					timeCountDown={timeClickWithDraw}
					onClickWithDraw={() => {
						setShowPopupUSDT(false);
						setShowWithDraw(true);
					}}
				/>
			)}
			{isShowWithDraw && (
				<ModalWithDrawUSDT
					onClosePopup={() => setShowWithDraw(false)}
					title="You can claim $5 USDT!"
					des="Congratulation! Your account is verified and eligible for withdrawal."
				/>
			)}
			{isShowConnectWalelt && (
				<WalletConnect onClose={() => setShowConnectWallet(false)} />
			)}
		</div>
	);
};

export default ProfileTeleGramMiniApp;
