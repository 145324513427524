import { avalanche, avalancheFuji, base, baseSepolia } from "viem/chains";
import { createConfig, http } from "wagmi";
import { injected, metaMask, walletConnect } from "wagmi/connectors";

export const configMainnet = createConfig({
	chains: [base, baseSepolia, avalanche, avalancheFuji],
	connectors: [
		injected(),
		metaMask(),
		walletConnect({
			projectId: "e6b8be1e206c3561ec914bbba97f456a",
			showQrModal: false,
			metadata: {
				name: "Slime Revolution",
				description: "Demo description",
				url: "https://slimerevolution.com/",
				icons: ["https://avatars.githubusercontent.com/u/37784886"],
			},
		}),
	],
	transports: {
		[avalanche.id]: http(),
		[avalancheFuji.id]: http(),
		[baseSepolia.id]: http(),
		[base.id]: http(),
	},
});